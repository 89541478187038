// export default (_to, _from, savedPosition) => savedPosition || {
//   x: 0,
//   y: 0,
// };
export default (_to, _from, savedPosition) => {
  if (savedPosition) {
    return savedPosition;
  }

  if (_from.path === _to.path) {
    return {};
  }
  return { x: 0, y: 0 };
};
