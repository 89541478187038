import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _f2766204 = () => interopDefault(import('../_theme/pages/Home.vue' /* webpackChunkName: "" */))
const _b7b96682 = () => interopDefault(import('../_theme/pages/Cart.vue' /* webpackChunkName: "_theme/pages/Cart" */))
const _10cad7c6 = () => interopDefault(import('../_theme/pages/Category.vue' /* webpackChunkName: "_theme/pages/Category" */))
const _3561c065 = () => interopDefault(import('../_theme/pages/Checkout.vue' /* webpackChunkName: "" */))
const _7d9dd69e = () => interopDefault(import('../_theme/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _5dd9ed26 = () => interopDefault(import('../_theme/pages/Checkout/ErrorPage.vue' /* webpackChunkName: "" */))
const _5944ac60 = () => interopDefault(import('../_theme/pages/Checkout/ExternalCheckoutThankYou.vue' /* webpackChunkName: "" */))
const _5fe066bc = () => interopDefault(import('../_theme/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _b6a89030 = () => interopDefault(import('../_theme/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _e38cf8e2 = () => interopDefault(import('../_theme/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _17159398 = () => interopDefault(import('../_theme/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _124157cf = () => interopDefault(import('../_theme/pages/ContactUs.vue' /* webpackChunkName: "" */))
const _a50ae262 = () => interopDefault(import('../_theme/pages/ContactUsEe.vue' /* webpackChunkName: "" */))
const _0c174047 = () => interopDefault(import('../_theme/pages/LookBook.vue' /* webpackChunkName: "_theme/pages/LookBook" */))
const _9c1edb5c = () => interopDefault(import('../_theme/pages/MyAccount.vue' /* webpackChunkName: "_theme/pages/MyAccount" */))
const _780a392d = () => interopDefault(import('../_theme/pages/MyAccount/AddressesDetails.vue' /* webpackChunkName: "_theme/pages/MyAccount/AddressesDetails" */))
const _203adc18 = () => interopDefault(import('../_theme/pages/MyAccount/BillingDetails.vue' /* webpackChunkName: "_theme/pages/MyAccount/BillingDetails" */))
const _1c17971c = () => interopDefault(import('../_theme/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "_theme/pages/MyAccount/MyNewsletter" */))
const _bafdec80 = () => interopDefault(import('../_theme/pages/MyAccount/MyProfile.vue' /* webpackChunkName: "_theme/pages/MyAccount/MyProfile" */))
const _658d545a = () => interopDefault(import('../_theme/pages/MyAccount/OrderHistory.vue' /* webpackChunkName: "_theme/pages/MyAccount/OrderHistory" */))
const _8769d752 = () => interopDefault(import('../_theme/pages/MyAccount/ShippingDetails.vue' /* webpackChunkName: "_theme/pages/MyAccount/ShippingDetails" */))
const _520c36a4 = () => interopDefault(import('../_theme/pages/Page.vue' /* webpackChunkName: "_theme/pages/Page" */))
const _00c68fa0 = () => interopDefault(import('../_theme/pages/Product.vue' /* webpackChunkName: "_theme/pages/Product" */))
const _2baee37b = () => interopDefault(import('../_theme/pages/ResetPassword.vue' /* webpackChunkName: "" */))
const _3f9b6b8c = () => interopDefault(import('../_theme/pages/Route.vue' /* webpackChunkName: "_theme/pages/Route" */))
const _3c275ae6 = () => interopDefault(import('../_theme/pages/Story.vue' /* webpackChunkName: "_theme/pages/Story" */))
const _af30bd6e = () => interopDefault(import('../_theme/pages/Errors/DefaultErrorCode.vue' /* webpackChunkName: "_theme/pages/Errors/DefaultErrorCode" */))
const _29729a78 = () => interopDefault(import('../_theme/pages/Errors/ErrorCode404.vue' /* webpackChunkName: "_theme/pages/Errors/ErrorCode404" */))
const _5e2077b5 = () => interopDefault(import('../_theme/pages/Errors/ErrorCode500.vue' /* webpackChunkName: "_theme/pages/Errors/ErrorCode500" */))
const _5e4abe38 = () => interopDefault(import('../_theme/pages/Errors/ErrorCode503.vue' /* webpackChunkName: "_theme/pages/Errors/ErrorCode503" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _f2766204,
    meta: {"hideNavigation":true},
    name: "home___en"
  }, {
    path: "/it",
    component: _f2766204,
    meta: {"hideNavigation":true},
    name: "home___it"
  }, {
    path: "/en/Cart",
    component: _b7b96682,
    name: "Cart___en"
  }, {
    path: "/en/Category",
    component: _10cad7c6,
    name: "Category___en"
  }, {
    path: "/en/checkout",
    component: _3561c065,
    name: "checkout___en",
    children: [{
      path: "billing",
      component: _7d9dd69e,
      name: "billing___en"
    }, {
      path: "error-page",
      component: _5dd9ed26,
      name: "error-page___en"
    }, {
      path: "external-thank-you",
      component: _5944ac60,
      name: "external-thank-you___en"
    }, {
      path: "payment",
      component: _5fe066bc,
      name: "payment___en"
    }, {
      path: "shipping",
      component: _b6a89030,
      name: "shipping___en"
    }, {
      path: "thank-you",
      component: _e38cf8e2,
      name: "thank-you___en"
    }, {
      path: "user-account",
      component: _17159398,
      name: "user-account___en"
    }]
  }, {
    path: "/en/Checkout",
    component: _3561c065,
    name: "Checkout___en",
    children: [{
      path: "Billing",
      component: _7d9dd69e,
      name: "Checkout-Billing___en"
    }, {
      path: "ErrorPage",
      component: _5dd9ed26,
      name: "Checkout-ErrorPage___en"
    }, {
      path: "ExternalCheckoutThankYou",
      component: _5944ac60,
      name: "Checkout-ExternalCheckoutThankYou___en"
    }, {
      path: "Payment",
      component: _5fe066bc,
      name: "Checkout-Payment___en"
    }, {
      path: "Shipping",
      component: _b6a89030,
      name: "Checkout-Shipping___en"
    }, {
      path: "ThankYou",
      component: _e38cf8e2,
      name: "Checkout-ThankYou___en"
    }, {
      path: "UserAccount",
      component: _17159398,
      name: "Checkout-UserAccount___en"
    }]
  }, {
    path: "/en/contact-us",
    component: _124157cf,
    name: "contact-us___en"
  }, {
    path: "/en/contact-us-v2",
    component: _a50ae262,
    name: "contact-us-v2___en"
  }, {
    path: "/en/ContactUs",
    component: _124157cf,
    name: "ContactUs___en"
  }, {
    path: "/en/ContactUsEe",
    component: _a50ae262,
    name: "ContactUsEe___en"
  }, {
    path: "/en/Home",
    component: _f2766204,
    name: "Home___en"
  }, {
    path: "/en/LookBook",
    component: _0c174047,
    name: "LookBook___en"
  }, {
    path: "/en/MyAccount",
    component: _9c1edb5c,
    name: "MyAccount___en",
    children: [{
      path: "AddressesDetails",
      component: _780a392d,
      name: "MyAccount-AddressesDetails___en"
    }, {
      path: "BillingDetails",
      component: _203adc18,
      name: "MyAccount-BillingDetails___en"
    }, {
      path: "MyNewsletter",
      component: _1c17971c,
      name: "MyAccount-MyNewsletter___en"
    }, {
      path: "MyProfile",
      component: _bafdec80,
      name: "MyAccount-MyProfile___en"
    }, {
      path: "OrderHistory",
      component: _658d545a,
      name: "MyAccount-OrderHistory___en"
    }, {
      path: "ShippingDetails",
      component: _8769d752,
      name: "MyAccount-ShippingDetails___en"
    }]
  }, {
    path: "/en/Page",
    component: _520c36a4,
    name: "Page___en"
  }, {
    path: "/en/Product",
    component: _00c68fa0,
    name: "Product___en"
  }, {
    path: "/en/reset-password",
    component: _2baee37b,
    alias: "/customer/account/createPassword",
    name: "reset-password___en"
  }, {
    path: "/en/ResetPassword",
    component: _2baee37b,
    name: "ResetPassword___en"
  }, {
    path: "/en/Route",
    component: _3f9b6b8c,
    name: "Route___en"
  }, {
    path: "/en/Story",
    component: _3c275ae6,
    name: "Story___en"
  }, {
    path: "/it/Cart",
    component: _b7b96682,
    name: "Cart___it"
  }, {
    path: "/it/Category",
    component: _10cad7c6,
    name: "Category___it"
  }, {
    path: "/it/checkout",
    component: _3561c065,
    name: "checkout___it",
    children: [{
      path: "billing",
      component: _7d9dd69e,
      name: "billing___it"
    }, {
      path: "error-page",
      component: _5dd9ed26,
      name: "error-page___it"
    }, {
      path: "external-thank-you",
      component: _5944ac60,
      name: "external-thank-you___it"
    }, {
      path: "payment",
      component: _5fe066bc,
      name: "payment___it"
    }, {
      path: "shipping",
      component: _b6a89030,
      name: "shipping___it"
    }, {
      path: "thank-you",
      component: _e38cf8e2,
      name: "thank-you___it"
    }, {
      path: "user-account",
      component: _17159398,
      name: "user-account___it"
    }]
  }, {
    path: "/it/Checkout",
    component: _3561c065,
    name: "Checkout___it",
    children: [{
      path: "Billing",
      component: _7d9dd69e,
      name: "Checkout-Billing___it"
    }, {
      path: "ErrorPage",
      component: _5dd9ed26,
      name: "Checkout-ErrorPage___it"
    }, {
      path: "ExternalCheckoutThankYou",
      component: _5944ac60,
      name: "Checkout-ExternalCheckoutThankYou___it"
    }, {
      path: "Payment",
      component: _5fe066bc,
      name: "Checkout-Payment___it"
    }, {
      path: "Shipping",
      component: _b6a89030,
      name: "Checkout-Shipping___it"
    }, {
      path: "ThankYou",
      component: _e38cf8e2,
      name: "Checkout-ThankYou___it"
    }, {
      path: "UserAccount",
      component: _17159398,
      name: "Checkout-UserAccount___it"
    }]
  }, {
    path: "/it/contact-us",
    component: _124157cf,
    name: "contact-us___it"
  }, {
    path: "/it/contact-us-v2",
    component: _a50ae262,
    name: "contact-us-v2___it"
  }, {
    path: "/it/ContactUs",
    component: _124157cf,
    name: "ContactUs___it"
  }, {
    path: "/it/ContactUsEe",
    component: _a50ae262,
    name: "ContactUsEe___it"
  }, {
    path: "/it/Home",
    component: _f2766204,
    name: "Home___it"
  }, {
    path: "/it/LookBook",
    component: _0c174047,
    name: "LookBook___it"
  }, {
    path: "/it/MyAccount",
    component: _9c1edb5c,
    name: "MyAccount___it",
    children: [{
      path: "AddressesDetails",
      component: _780a392d,
      name: "MyAccount-AddressesDetails___it"
    }, {
      path: "BillingDetails",
      component: _203adc18,
      name: "MyAccount-BillingDetails___it"
    }, {
      path: "MyNewsletter",
      component: _1c17971c,
      name: "MyAccount-MyNewsletter___it"
    }, {
      path: "MyProfile",
      component: _bafdec80,
      name: "MyAccount-MyProfile___it"
    }, {
      path: "OrderHistory",
      component: _658d545a,
      name: "MyAccount-OrderHistory___it"
    }, {
      path: "ShippingDetails",
      component: _8769d752,
      name: "MyAccount-ShippingDetails___it"
    }]
  }, {
    path: "/it/Page",
    component: _520c36a4,
    name: "Page___it"
  }, {
    path: "/it/Product",
    component: _00c68fa0,
    name: "Product___it"
  }, {
    path: "/it/reset-password",
    component: _2baee37b,
    alias: "/customer/account/createPassword",
    name: "reset-password___it"
  }, {
    path: "/it/ResetPassword",
    component: _2baee37b,
    name: "ResetPassword___it"
  }, {
    path: "/it/Route",
    component: _3f9b6b8c,
    name: "Route___it"
  }, {
    path: "/it/Story",
    component: _3c275ae6,
    name: "Story___it"
  }, {
    path: "/en/Errors/DefaultErrorCode",
    component: _af30bd6e,
    name: "Errors-DefaultErrorCode___en"
  }, {
    path: "/en/Errors/ErrorCode404",
    component: _29729a78,
    name: "Errors-ErrorCode404___en"
  }, {
    path: "/en/Errors/ErrorCode500",
    component: _5e2077b5,
    name: "Errors-ErrorCode500___en"
  }, {
    path: "/en/Errors/ErrorCode503",
    component: _5e4abe38,
    name: "Errors-ErrorCode503___en"
  }, {
    path: "/it/Errors/DefaultErrorCode",
    component: _af30bd6e,
    name: "Errors-DefaultErrorCode___it"
  }, {
    path: "/it/Errors/ErrorCode404",
    component: _29729a78,
    name: "Errors-ErrorCode404___it"
  }, {
    path: "/it/Errors/ErrorCode500",
    component: _5e2077b5,
    name: "Errors-ErrorCode500___it"
  }, {
    path: "/it/Errors/ErrorCode503",
    component: _5e4abe38,
    name: "Errors-ErrorCode503___it"
  }, {
    path: "/en/c/:categoryId",
    component: _10cad7c6,
    name: "category___en"
  }, {
    path: "/en/lookbook/:path+",
    component: _0c174047,
    name: "lookbook___en"
  }, {
    path: "/en/my-account/:pageName?",
    component: _9c1edb5c,
    name: "my-account___en"
  }, {
    path: "/en/p/:productId",
    component: _00c68fa0,
    name: "product___en"
  }, {
    path: "/it/c/:categoryId",
    component: _10cad7c6,
    name: "category___it"
  }, {
    path: "/it/lookbook/:path+",
    component: _0c174047,
    name: "lookbook___it"
  }, {
    path: "/it/my-account/:pageName?",
    component: _9c1edb5c,
    name: "my-account___it"
  }, {
    path: "/it/p/:productId",
    component: _00c68fa0,
    name: "product___it"
  }, {
    path: "/en/:path+",
    component: _3f9b6b8c,
    name: "route-resolver___en"
  }, {
    path: "/it/:path+",
    component: _3f9b6b8c,
    name: "route-resolver___it"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
